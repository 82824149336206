/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/css/custom.scss'
import { initCookiePolicy } from './src/utility/onetrust';


export const onRouteUpdate = (params) => {
  initCookiePolicy();
}