// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-comunicazioni-e-news-js": () => import("./../../../src/pages/comunicazioni-e-news.js" /* webpackChunkName: "component---src-pages-comunicazioni-e-news-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-template-news-js": () => import("./../../../src/template/news.js" /* webpackChunkName: "component---src-template-news-js" */),
  "component---src-template-pagina-interna-js": () => import("./../../../src/template/pagina-interna.js" /* webpackChunkName: "component---src-template-pagina-interna-js" */),
  "component---src-template-pagina-quotazioni-js": () => import("./../../../src/template/pagina-quotazioni.js" /* webpackChunkName: "component---src-template-pagina-quotazioni-js" */),
  "component---src-template-pagina-storico-js": () => import("./../../../src/template/pagina-storico.js" /* webpackChunkName: "component---src-template-pagina-storico-js" */)
}

